import React, { useState, useRef, Fragment, useEffect } from "react";
import {
  UploadWrapper,
  UploadCont,
  Dragger,
  HintWrapper
} from "./uploadStyles";
import { Icon, message, Input, Tabs } from "antd";
import { ColCenter, RowCenter, Row } from "../appClassic.style";
import { debounce } from "lodash";
import {
  api,
  ROUTE_VERIFY_USER_CODE_ID,
  ROUTE_ANALYSE_IMAGE,
  ROUTE_REGISTER_USER
} from "../../../utils/api";
import { getCrispName } from "../../../utils/crisp";
import { isProd, getRandomNumber } from "../../../utils";
import withLocation from "../../../components/withLocation";
import { injectIntl } from "gatsby-plugin-intl";
import { useFirebase } from "gatsby-plugin-firebase";

import Marker, { DEFAULT_MARKER_SELECTED } from "../Marker/Marker";
import { setItem, getItem } from "../../../utils/localStorage";
import Image from "reusecore/src/elements/Image";

import APP_HINT_DOWNLOAD, {
  apizoom_example_small
} from "../../../utils/images";
const { TabPane } = Tabs;

const rootIntl = "upload.";
const Upload = ({ search, intl }) => {
  const [userCode, setUserCode] = useState(null);
  const [user, setUser] = useState(null);
  const [marker_selected, setMarker] = useState(DEFAULT_MARKER_SELECTED);
  const [defaultActiveKey, setdefaultActiveKey] = useState("2");
  const [demoTabSelected, setDemoTabSelected] = useState(true);
  const [calibMode, setCalibMode] = useState(true);
  const [uploadedFileListState, setUploadedFileListState] = useState([]);
  const uploadedFileList = useRef([]);
  const currentFile = useRef(null);
  const translate = (key) => intl.formatMessage({ id: `${rootIntl}${key}` });

  const firebaseRef = useRef(null);
  useFirebase((firebase) => {
    firebaseRef.current = firebase;
  }, []);

  const reset = () => {
    setUserCode(null);
    setUser(null);
    setCalibMode(true);
    setUploadedFileListState([]);
    uploadedFileList.current = [];
    currentFile.current = null;
  };

  const setCurrentFileStatus = (status, error_msg = "", res) => {
    let display_msg = error_msg;
    if (error_msg) {
      display_msg = error_msg.includes("(most recent call last)")
        ? ""
        : error_msg;
    }
    console.log("status changed ", status);
    console.log(uploadedFileList.current, currentFile.current);

    let currentFileInlist = uploadedFileList.current.find(
      (f) => f.uid === (res ? res.file : currentFile.current).uid
    );

    const setResultSuccess = () => {
      if (currentFileInlist == null)
        currentFileInlist = { ...currentFile.current };
      const varroas_count = `${res.varroas_count} ${translate("varroas")}`;
      currentFileInlist.name = `${translate("success")} ${varroas_count}`;
      currentFileInlist.photo = res.photo;
      currentFileInlist.varroas_count = res.varroas_count;
      currentFileInlist.status = "done";
    };
    if (!currentFileInlist) {
      return;
    } else if (status === "uploading") {
      currentFileInlist.name = translate("uploading");
      currentFileInlist.status = "uploading";
    } else if (status === "error") {
      const msg = `${translate("file_upload_failed")} ${
        display_msg ? `: ${display_msg}` : ""
      }`;
      message.error(msg);
      currentFileInlist.name = msg;
      currentFileInlist.status = "error";
    } else {
      setResultSuccess();
    }
    currentFile.current = currentFileInlist;
    setUploadedFileListState([...uploadedFileList.current]);
    console.log("new uploadedFileList", uploadedFileList.current);
  };

  const uploadProps = {
    multiple: true,
    listType: "picture",
    beforeUpload: async (file) => {
      let user_id = user?.id;
      // let anonymous_mode = demoTabSelected;
      if (!user) {
        if (!demoTabSelected) return;
        const userCredentials = await firebaseRef.current
          .auth()
          .signInWithEmailAndPassword(
            "chevassusgaspard+webtest@gmail.com",
            "333333"
          );
        // console.log(userCredentials);
        // const isNewUser = userCredentials?.additionalUserInfo?.isNewUser;
        // const firebaseId = userCredentials.user.uid;
        // const fullname = getCrispName();
        setUser(userCredentials.user);
        user_id = "6047305718afa26ca1004607";

        // if (isNewUser) {
        //   const username = `${fullname}_${getRandomNumber()}`;
        //   const fakeEmail = `fake_email_${username}@mail.com`;
        //   const token = await firebaseRef.current
        //     .auth()
        //     .currentUser.getIdToken(true);
        //   const userRegistered = await api.post(
        //     ROUTE_REGISTER_USER,
        //     {
        //       fullname,
        //       username,
        //       email: fakeEmail,
        //       locale: intl.locale
        //     },
        //     {
        //       headers: {
        //         Authorization: token
        //       }
        //     }
        //   );
        //   console.log("user registered", userRegistered);
        //   user_id = userRegistered.data._id;
        //   setUser({ id: user_id, name: fullname });
        // } else {
        //   const res = await api.post(ROUTE_VERIFY_USER_CODE_ID, {
        //     user_code: firebaseId.slice(0, 6)
        //   });
        //   const newUser = res.data.user;
        //   user_id = newUser.id;
        //   setUser(newUser);
        // }
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("SCAN_SET_ID", Date.now().toString());
      formData.append("PARTNER_ID", "6047305718afa26ca1004607");
      formData.append("PARTNER_KEY", "6047305718afa26ca1004607");
      // formData.append("calib_mode", calibMode);
      formData.append("anonymous_mode", "true");
      // if (anonymous_mode) {
      //   formData.append("marker_selected", marker_selected);
      // }
      formData.append("calib_mode", "false");
      formData.append("direct_mode", "true");
      formData.append("direct_mode_apiary_id", "604730902fb09a66afb8b86e");
      formData.append("direct_mode_beehive_id", "6047309c2fb09a66afb8b86f");
      formData.append("user_model", "yolo");
      formData.append("marker_selected", marker_selected);

      const token = (
        await firebaseRef.current.auth().currentUser.getIdTokenResult(true)
      ).token;

      setCurrentFileStatus("uploading");
      api
        .post(ROUTE_ANALYSE_IMAGE, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: token
          }
        })
        .then((res) => {
          const {
            data: { varroas_count, camera_date_diff, photo }
          } = res;
          console.log(res);
          setCurrentFileStatus("done", "", { file, varroas_count, photo });
          if (calibMode) {
            setUser({ ...user, camera_date_diff });
            return setCalibMode(false);
          }
        })
        .catch(({ response = { data: {} } }) => {
          const {
            data: { error_msg, new_camera_detected, no_match_image }
          } = response;

          setCurrentFileStatus(
            "error",
            no_match_image ? translate("no_match") : error_msg
          );
          if (new_camera_detected) {
            message.warning(translate("new_cam"));
          }
        });
      return false;
    },
    onChange({ file, fileList }) {
      if (file.status === "uploading" || file.status === undefined) {
        const currentFileFound = fileList.find((f) => f.uid === file.uid);
        currentFileFound.name = translate("uploading");
        currentFile.current = currentFileFound;
        uploadedFileList.current = fileList;
        setUploadedFileListState(uploadedFileList.current);
      }
    },
    fileList: uploadedFileListState,
    onRemove: (file) => {
      uploadedFileList.current = [
        ...uploadedFileListState.filter((f) => f.uid !== file.uid)
      ];
      setUploadedFileListState(uploadedFileList.current);
    }
  };

  const debouncedCall = useRef(
    debounce((user_code) => {
      if (user_code.length !== 6) {
        return message.warning(translate("min_char"));
      }

      api
        .post(ROUTE_VERIFY_USER_CODE_ID, {
          user_code
        })
        .then(({ data: { user } }) => {
          setUser(user);
          setCalibMode(user.camera_date_diff ? false : true);
        })
        .catch((e) => {
          message.error(translate("error_code"));
          setCalibMode(true);
        });
    }, 1000)
  ).current;

  const setUserCodeFunc = (code = "") => {
    reset();
    setUserCode(code);
    debouncedCall(code);
  };
  let hasAMarkerBeenSaved = false;

  useEffect(() => {
    // const { userCode, activeTab: activeKey } = search;
    // if (userCode) setUserCodeFunc(userCode);
    // if (!userCode && !isProd()) setUserCodeFunc('LVy1zb');
    // if (activeKey) {
    //   const isDemo = activeKey === "2";
    //   setDemoTabSelected(isDemo);
    //   if (isDemo) setCalibMode(false);
    //   setdefaultActiveKey(activeKey);
    // }
    const marker_saved = getItem("marker");
    if (marker_saved) {
      setMarker(marker_saved);
      hasAMarkerBeenSaved = true;
    }
  }, []);

  const changeToCalibration = (e) => {
    e.stopPropagation();
    setCalibMode(!calibMode);
  };
  const renderCalibDone = () => {
    const { camera_date_diff } = user;
    const camera_date_diff_s = Math.abs(
      Math.round((camera_date_diff / 1000) % 60)
    );
    const camera_date_diff_m = Math.abs(Math.trunc(camera_date_diff / 60000));

    return (
      <p className="ant-upload-hint">
        {`${translate("you_have")} ${camera_date_diff_m}
          ${translate("min")} ${camera_date_diff_s} ${translate("second")}${
          camera_date_diff_s > 1 ? "s" : ""
        }`}
        <br />
        <span className="underline" onClick={(e) => changeToCalibration(e)}>
          {calibMode ? translate("upload_photo") : translate("upload_calib")}
        </span>
      </p>
    );
  };
  const renderUpload = (text, calibDone = true) => {
    const displayCalibDone = calibDone && !demoTabSelected;
    return (
      <Fragment>
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">{text}</p>
        {displayCalibDone && renderCalibDone()}
      </Fragment>
    );
  };

  const renderWarningMessage = () => {
    let msg = "";
    if (!userCode) msg = translate("must_code");
    else if (userCode && !user) msg = translate("code_invalid");
    return <p className="warningMsg">{msg}</p>;
  };

  const renderSuccessMessage = () => {
    const crispName = getCrispName();
    const userName = user && !demoTabSelected ? user.name : crispName;
    let msg =
      user || demoTabSelected
        ? `${translate("hello")} ${userName} ! ${
            demoTabSelected ? "" : translate("identified")
          }`
        : "";
    if (msg === "") return;
    return <p className="successMessage">{msg}</p>;
  };

  const ContentWithAccount = () => {
    const displayUpload = demoTabSelected || (userCode && user);
    const results = uploadedFileListState.filter(
      (f) => f.varroas_count != null
    );
    return (
      <UploadCont style={{ width: "100%" }}>
        <div className="spanContainer">
          <Dragger {...uploadProps}>
            {!demoTabSelected && (
              <div className="personalCont">
                <p>Personnal user code: </p>
                <Input
                  className="input"
                  placeholder={translate("code_placeholder")}
                  value={userCode}
                  onChange={(e) => setUserCodeFunc(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                ></Input>
                {!user && <p>{translate("code_hint")}</p>}
              </div>
            )}

            <div className="uploadCont">
              {!demoTabSelected && renderWarningMessage()}
              {renderSuccessMessage()}
              {displayUpload &&
                renderUpload(
                  calibMode && !demoTabSelected
                    ? translate("upload_calib_text")
                    : translate("upload_all"),
                  !calibMode
                )}
            </div>
          </Dragger>
          {results && results.length > 0 && <div>{translate("results")}</div>}
          {results.map((f, ind) => (
            <Row key={ind} style={{ justifyContent: "space-around" }}>
              <a
                href={f?.photo?.replace("uploads_analyzed", "analyzed")}
              >{`${translate("see_photo") +
                " " +
                f.varroas_count +
                " " +
                translate("varroas")} `}</a>
            </Row>
          ))}
        </div>
      </UploadCont>
    );
  };
  return (
    <UploadWrapper>
      <div>
        {/* <Tabs
          defaultActiveKey={"1"}
          size={"default"}
          onChange={(activeK) => {
            const isDemoTabSelected =
              activeK === (defaultActiveKey === "1" ? "2" : "1");
            setDemoTabSelected(isDemoTabSelected);
            if (isDemoTabSelected) setCalibMode(false);
          }}
        > */}
        {/* <TabPane tab={translate("do_account")} key={defaultActiveKey}>
            {ContentWithAccount()}
          </TabPane> */}
        {/* <TabPane
            tab={translate("do_not_account")}
            key={defaultActiveKey === "1" ? "2" : "1"}
          > */}
        <ColCenter>
          {hasAMarkerBeenSaved ? ContentWithAccount() : null}
          <Marker
            marker={marker_selected}
            onChange={(marker) => {
              setMarker(marker);
              setItem("marker", marker);
            }}
          />
          <Image
            width="400px"
            src={apizoom_example_small}
            alt="example result"
          />
          {hasAMarkerBeenSaved ? null : ContentWithAccount()}
          <HintDownload text={translate("download")} />
        </ColCenter>
        {/* </TabPane>
        </Tabs> */}
      </div>
    </UploadWrapper>
  );
};

export default injectIntl(withLocation(Upload));

const HintDownload = ({ text }) => {
  return (
    <HintWrapper>
      <img src={APP_HINT_DOWNLOAD}></img>
      <p>{text}</p>
    </HintWrapper>
  );
};
