import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/src/theme/appclassic';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppClassic/Navbar';
import Footer from '../containers/AppClassic/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper
} from '../containers/AppClassic/appClassic.style';
import { injectIntl } from 'gatsby-plugin-intl';
import SEO from '../components/seo';
import Upload from '../containers/AppClassic/Upload/Upload';
import Survey from '../containers/AppClassic/Survey';

const UploadPage = ({ intl }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title={intl.formatMessage({ id: 'Seo' })} />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass='sticky-active'>
            <Navbar scan={true} />
          </Sticky>
          <ContentWrapper>
            <Upload />
          </ContentWrapper>
          <Survey />
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};

export default injectIntl(UploadPage);
