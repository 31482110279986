import React from 'react';
import { TYPEFORM_SURVEY_FR } from '../../../utils/const';
import SurveyWrapper from './styles';
import { injectIntl } from 'gatsby-plugin-intl';
const Survey = ({ intl }) => (
  <SurveyWrapper>
    <p>
      {intl.formatMessage({ id: 'survey.question1' }) + ' '}
      <span
        className='underline'
        onClick={() => {
          window.open(TYPEFORM_SURVEY_FR);
        }}
      >
        {' '}
        {intl.formatMessage({ id: 'survey.question2' })}
      </span>
    </p>
  </SurveyWrapper>
);

export default injectIntl(Survey);
