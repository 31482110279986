import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { RowCenter } from '../appClassic.style';
import { media } from '../../../theme/mediaQueryHelper';
import { Upload as UploadAnt } from 'antd';
import Metrics from '../../../theme/Metrics';

export const UploadWrapper = styled.div`
  margin-top: 100px;

  > div {
    margin-left: 15%;
    margin-right: 15%;
    @media (max-width: 767px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .personalCont {
    flex-direction: column;
    display: flex;
    align-items: flex-start;

    > p {
      text-align: left;
    }
  }

  .warningMsg {
    color: ${themeGet('colors.secondary')};
  }

  .ant-upload-hint {
    margin-top: 24px;
  }

  .successMessage {
    color: ${themeGet('colors.green')};
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 18px;
  }

  .uploadCont {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .ant-upload {
    width: 80%;
    display: flex;
    justify-content: center;
    margin: 24px auto;
  }
  .anticon-download {
    display: none;
  }

  .input {
    width: 80%;
  }

  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }
  .upload-list-inline .ant-upload-animate-enter {
    animation-name: uploadAnimateInlineIn;
  }
  .upload-list-inline .ant-upload-animate-leave {
    animation-name: uploadAnimateInlineOut;
  }
`;

export const UploadCont = styled(RowCenter)`
  .spanContainer {
    width: 100%;
  }
  .spanContainer > span {
    width: 80%;
    padding: 30px;
    ${media.down('sm')} {
      width: 100%;
    }
  }
`;

const { Dragger: AntDragger } = UploadAnt;
export const Dragger = styled(AntDragger)``;

export const HintWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: ${Metrics.largePadding * 1.5}px;
  transition: box-shadow 0.25s;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  img {
    width: 100%;
    max-width: 500px;
    object-fit: contain;
    margin-bottom: 20px;
  }

  p {
    text-align: center;
  }
`;
