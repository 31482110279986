import { isBrowser } from '.';

export const getCrispName = () => {
  const fallbackName = 'anonymous_visitor';
  try {
    if (!isBrowser() || window.$crisp == null) {
      return fallbackName;
    }
    const crispname = window.$crisp.get('user:nickname');
    if (crispname != null && crispname !== 'null') {
      return crispname;
    }
    return fallbackName;
  } catch (e) {
    console.log(e);
    return fallbackName;
  }
};
