const Metrics = {
  marginHorizontal: 10,
  marginVertical: 10,
  section: 20,
  largeMargin: 20,
  largePadding: 20,
  baseMargin: 10,
  basePadding: 10,
  smallPadding: 5,
  doubleBaseMargin: 20,
  smallMargin: 5,
  doubleSection: 50,
  horizontalLineHeight: 1,
  buttonRadius: 4,
  icons: {
    tiny: 15,
    small: 20,
    medium: 30,
    large: 45,
    xl: 50
  },
  images: {
    small: 20,
    medium: 40,
    large: 60,
    logo: 100
  }
};

export default Metrics;
