import styled from 'styled-components';
import { themeGet } from 'styled-system';

const SurveyWrapper = styled.div`
  padding: 10px;
  width: 100%;
  justify-content: center;

  p {
    text-align: center;
  }
`;
export default SurveyWrapper;
