import React, { useState, useEffect } from 'react';
import { Separator, MarkerWrapper, MarkerImg, MarkerContainer } from './styles';
import { Input, Button } from 'antd';
import { injectIntl } from 'gatsby-plugin-intl';
import Metrics from '../../../theme/Metrics';
import {
  custom_coin,
  marker_1_CHF,
  marker_1_EUR,
  marker_2_CHF,
  marker_2_EUR,
  marker_50_EUR,
  marker_25_DOLL,
  marker_50_DOLL,
} from '../../../utils/images';
import { ColCenter, RowCenter } from '../appClassic.style';
const CUSTOM_COIN_STR = 'custom_coin';
const CUSTOM_COIN_SEP = '**';

export const markers_availbale = [
  { image: marker_1_CHF, name: 'marker_1_CHF' },
  { image: marker_1_EUR, name: 'marker_1_EUR' },
  { image: marker_2_CHF, name: 'marker_2_CHF' },
  { image: marker_2_EUR, name: 'marker_2_EUR' },
  { image: marker_50_EUR, name: 'marker_50_EUR' },
  { image: marker_25_DOLL, name: 'marker_25_DOLL' },
  { image: marker_50_DOLL, name: 'marker_50_DOLL' },
  { image: custom_coin, name: CUSTOM_COIN_STR },
];
const styles = {
  label: { textAlign: 'left' },
};
export const DEFAULT_MARKER_SELECTED = markers_availbale[0].name;
const getCustomDiameterFromName = (marker_selected) => {
  if (marker_selected.includes(CUSTOM_COIN_STR)) {
    return marker_selected.split(CUSTOM_COIN_SEP)[1];
  }
  return '';
};
export const getMarkerSelected = (marker_selected) => {
  let marker = markers_availbale[0];
  if (marker_selected) {
    const marker_found = markers_availbale.find((m) =>
      marker_selected.includes(m.name)
    );
    if (marker_found) {
      marker = marker_found;
      marker.name = marker_selected;
    }
  }
  return marker;
};

const Marker = ({
  intl,
  onChange,
  marker: marker_selected = DEFAULT_MARKER_SELECTED,
}) => {
  const [customDiameter, setCustomDiameter] = useState('');
  const [customDimensions, setCustomDimensions] = useState(false);

  useEffect(() => {
    const customDiameter = getCustomDiameterFromName(marker_selected);
    setCustomDiameter(customDiameter);
  }, []);

  const selectMarker = (marker_selected, customDiameter) => {
    onChange(marker_selected);
    if (!customDiameter && marker_selected.includes(CUSTOM_COIN_STR)) {
      return setCustomDimensions(true);
    }
    setCustomDimensions(false);
  };

  return (
    <MarkerWrapper>
      <div className='shadow'>
        <p className='alignStart'>
          {intl.formatMessage({ id: 'marker.current' })}
          <br />
          <strong>{intl.formatMessage({ id: 'marker.action' })}</strong>
        </p>
        <MarkerComp
          isSelected={true}
          image={getMarkerSelected(marker_selected).image}
        />
        {marker_selected.includes(CUSTOM_COIN_STR) && (
          <div>
            <p>{`${intl.formatMessage({
              id: 'marker.diameterInfo',
            })}  ${customDiameter}  cm`}</p>
          </div>
        )}
        <Separator />
        <p className='alignStart'>{`${intl.formatMessage({
          id: 'marker.other',
        })}`}</p>
        {customDimensions && (
          <ColCenter
            style={{
              padding: Metrics.basePadding,
              margin: Metrics.baseMargin,
            }}
          >
            <Input
              style={{ margin: 10 }}
              placeholder={'3.46 cm'}
              keyboardType='number-pad'
              value={customDiameter}
              onChange={(e) => {
                setCustomDiameter(e.target.value);
              }}
              title={`${intl.formatMessage({ id: 'marker.diameter' })}*`}
              titleStyle={styles.label}
            />
            <Button
              disabled={customDiameter === ''}
              onClick={() =>
                selectMarker(
                  `${CUSTOM_COIN_STR}${CUSTOM_COIN_SEP}${parseFloat(
                    customDiameter
                  ).toFixed(2)}`,
                  true
                )
              }
            >
              {intl.formatMessage({ id: 'marker.validate' })}
            </Button>
          </ColCenter>
        )}
        <RowCenter>
          <MarkerContainer>
            {markers_availbale
              .filter((marker) => marker.name !== marker_selected)
              .map((marker, ind) => (
                <MarkerComp
                  key={ind}
                  image={marker.image}
                  onClick={() => selectMarker(marker.name, false)}
                />
              ))}
          </MarkerContainer>
        </RowCenter>
      </div>
    </MarkerWrapper>
  );
};

export default injectIntl(Marker);

const MarkerComp = ({ image, onClick, ...props }) => (
  <MarkerImg onClick={onClick} {...props}>
    <img src={image} alt='marker_img' />
  </MarkerImg>
);
