import axios from "axios";
import { isProd } from ".";

const apiPrefixVersion = "/api/v1";
const baseUrl = isProd() ? "https://apizoom.epfl.ch" : "http://localhost:5000";

export const api = axios.create({
  baseURL: `${baseUrl}${apiPrefixVersion}`,
  headers: {
    "Content-Type": "application/json"
  }
});

export const ROUTE_VERIFY_USER_CODE_ID = "verify_user_code";
export const ROUTE_ANALYSE_IMAGE = "analyze_image";
export const ROUTE_REGISTER_USER = "register_m";
