import React from 'react';
import Metrics from '../../../theme/Metrics';
import styled, { css } from 'styled-components';
import { centerFlex } from '../../../containers/AppClassic/appClassic.style';
import colors from '../../../../../common/src/theme/appclassic/colors';
import { media } from '../../../theme/mediaQueryHelper';

export const MarkerWrapper = styled.div`
  ${centerFlex};
  width: 80%;
  ${media.down('sm')}{
    width: 100%;
  }
  padding: ${Metrics.largePadding * 1.5}px;
  flex-direction: row;

  .shadow {
    ${centerFlex}
    padding: ${Metrics.largePadding * 1.5}px;
    transition: box-shadow 0.25s;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }

  .alignStart {
    text-align: start;
  }
`;

export const MarkerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Separator = ({ margin }) => {
  return (
    <div style={{ width: '100%', alignItems: 'center' }}>
      <div
        style={{
          margin: 30,
          width: 60,
          borderWidth: 1,
          borderColor: '#ac92ec',
          ...(margin && { margin })
        }}
      />
    </div>
  );
};

export const MarkerImg = styled.div`
  width: 60px;
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isSelected }) =>
    isSelected
      ? `border-color: ${colors.primary};
  border-width: 2px;
  border-style: solid;
  width: 100px;
  padding: 4px;`
      : null}
  img {
    object-fit: contain;
    width: 100%;
  }
`;
