export const scrollTo = id => () => {
  const el = document.getElementById(id);
  if (el) return window.scrollTo(0, el.offsetTop - 20);
  return false;
};

export const isProd = () => process.env.NODE_ENV !== 'development';

export const getRandomNumber = () => Math.round(Math.random() * 100000000);

export const isBrowser = () => typeof window !== 'undefined';
